import uuid from "react-uuid";
import { auth, firestore } from "../firebaseConfig";
import {
  addDoc,
  collection,
  onSnapshot,
  doc,
  updateDoc,
  query,
  where,
  setDoc,
  deleteDoc,
  orderBy,
  getDoc,
  getDocs,
  serverTimestamp,
  arrayUnion,
  Timestamp,
  limit,
} from "firebase/firestore";
import { sendPasswordResetEmail } from "firebase/auth";

let postsRef = collection(firestore, "posts");
let userRef = collection(firestore, "users");
let likeRef = collection(firestore, "likes");
let reactionRef = collection(firestore, "reactions");
let commentsRef = collection(firestore, "comments");
let connectionRef = collection(firestore, "connections");
let postBlog = collection(firestore, "blog");
let commentsBlogRef = collection(firestore, "blogComments");
let postCommunity = collection(firestore, "community");
let userCommunityRef = collection(firestore, "userCommunity");
let userChatRef = collection(firestore, "userChats");
let chatRef = collection(firestore, "chats");
let eventRef = collection(firestore, "event");
let postNovenas = collection(firestore, "novenas");
let postPrayers = collection(firestore, "prayers");
let postAddPrayers = collection(firestore, "addPrayers");
let postChallenges = collection(firestore, "challenges");
let communityUserRef = collection(firestore, "CommunityUsers");
let prayerUserRef = collection(firestore, "prayerUsers");
let novenaUserRef = collection(firestore, "novenaUsers");
let challengeUserRef = collection(firestore, "challengeUsers");
let postMiracleRef = collection(firestore, "miracles");
let postThanksRef = collection(firestore, "thanks");
let novenasCommunityRef = collection(firestore, "novenaCommunity");
let prayerCommunityRef = collection(firestore, "prayerCommunity");
let postUsers = collection(firestore, "users");
let postProgramssRef = collection(firestore, "programs");
let challengeCommunityRef = collection(firestore, "challengeCommunity");
let novenaCommunityRef = collection(firestore, "novenaCommunity");
let programRef = collection(firestore, "programs");

export const postStatus = (object) => {
  addDoc(postsRef, object)
    .then(() => {
      console.log("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPost = async (id) => {
  let articulo = doc(postsRef, id);
  const docSnap = await getDoc(articulo);
  if (docSnap.exists()) {
    return docSnap.data();
  }
};

export const postMiracle = (object) => {
  addDoc(postMiracleRef, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postThanks = (destinationUserIds, message, userName) => {
  destinationUserIds.forEach((userId) => {
    addDoc(postThanksRef, {
      destinationUserIds: userId,
      message: message,
      userName: userName,
      status: false,
      createAt: serverTimestamp(),
    })
      .then(() => {
        console.log("Post has been added successfully");
        //   toast.success("Post has been added successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const getStatus = (setAllStatus) => {
  const q = query(
    postsRef,
    where("miracle", "==", false),
    where("community", "==", "")
  );
  onSnapshot(q, (response) => {
    setAllStatus(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getThanks = (userId, setThanks) => {
  const q = query(
    postThanksRef,
    where("destinationUserIds", "==", userId),
    orderBy("createAt", "desc")
  );
  onSnapshot(q, (response) => {
    setThanks(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getStatusCommunity = (setAllStatus, id) => {
  const q = query(postsRef, where("community", "==", id));
  onSnapshot(q, (response) => {
    setAllStatus(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getAllUsers = (setAllUsers) => {
  onSnapshot(userRef, (response) => {
    setAllUsers(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getSingleStatus = (setAllStatus, id) => {
  const singlePostQuery = query(
    postsRef,
    where("userID", "==", id)
    // where("community", "==", "")
  );
  onSnapshot(singlePostQuery, (response) => {
    setAllStatus(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getSingleUser = (setCurrentUser, email) => {
  const singleUserQuery = query(userRef, where("email", "==", email));
  onSnapshot(singleUserQuery, (response) => {
    setCurrentUser(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })[0]
    );
  });
};

export const postUserData = (id, object) => {
  setDoc(doc(userRef, id), object)
    .then(() => {})
    .catch((err) => {
      console.log(err);
    });
};

export const postUserChat = (id, object) => {
  setDoc(doc(userChatRef, id), object)
    .then(() => {})
    .catch((err) => {
      console.log(err);
    });
};

export const getCurrentUser = (setCurrentUser) => {
  onSnapshot(userRef, (response) => {
    setCurrentUser(
      response.docs
        .map((docs) => {
          return { ...docs.data(), id: docs.id };
        })
        .filter((item) => {
          return item.email === localStorage.getItem("userEmail");
        })[0]
    );
  });
};

export const editProfile = (userID, payload) => {
  let userToEdit = doc(userRef, userID);

  updateDoc(userToEdit, payload)
    .then(() => {
      console.log("Profile has been updated successfully");
      //   toast.success("Profile has been updated successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const likePost = (userId, postId, liked) => {
  try {
    let docToLike = doc(likeRef, `${userId}_${postId}`);
    if (liked) {
      // deleteDoc(docToLike);
    } else {
      setDoc(docToLike, { userId, postId });
    }
  } catch (err) {
    console.log(err);
  }
};

export const reactionPost = (userId, postId, reaction, type) => {
  try {
    let docToReaction = doc(reactionRef, `${userId}_${postId}`);
    if (reaction) {
      // deleteDoc(docToLike);
    } else {
      setDoc(docToReaction, { userId, postId, type });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getLikesByUser = (userId, postId, setLiked, setLikesCount) => {
  try {
    let likeQuery = query(likeRef, where("postId", "==", postId));

    onSnapshot(likeQuery, (response) => {
      let likes = response.docs.map((doc) => doc.data());
      // let likesCount = likes?.length;

      const isLiked = likes.some((like) => like.userId === userId);

      setLikesCount(likes);
      setLiked(isLiked);
    });
  } catch (err) {
    console.log(err);
  }
};

export const getReactionAbrazo = (
  userId,
  postId,
  setLiked,
  setReactionAbrazo
) => {
  try {
    let likeQuery = query(
      reactionRef,
      where("postId", "==", postId),
      where("type", "==", "Te abrazo")
    );

    onSnapshot(likeQuery, (response) => {
      let likes = response.docs.map((doc) => doc.data());
      let likesCount = likes?.length;

      const isLiked = likes.some((like) => like.userId === userId);

      setReactionAbrazo(likesCount);
      setLiked(isLiked);
    });
  } catch (err) {
    console.log(err);
  }
};

export const getReactionRezo = (userId, postId, setLiked, setReactionRezo) => {
  try {
    let likeQuery = query(
      reactionRef,
      where("postId", "==", postId),
      where("type", "==", "Rezo por ti")
    );

    onSnapshot(likeQuery, (response) => {
      let likes = response.docs.map((doc) => doc.data());
      let likesCount = likes?.length;

      const isLiked = likes.some((like) => like.userId === userId);

      setReactionRezo(likesCount);
      setLiked(isLiked);
    });
  } catch (err) {
    console.log(err);
  }
};

export const getReactionAcompaño = (
  userId,
  postId,
  setLiked,
  setReactionAcompaño
) => {
  try {
    let likeQuery = query(
      reactionRef,
      where("postId", "==", postId),
      where("type", "==", "Te acompaño")
    );

    onSnapshot(likeQuery, (response) => {
      let likes = response.docs.map((doc) => doc.data());
      let likesCount = likes?.length;

      const isLiked = likes.some((like) => like.userId === userId);

      setReactionAcompaño(likesCount);
      setLiked(isLiked);
    });
  } catch (err) {
    console.log(err);
  }
};

export const getReactionAmor = (userId, postId, setLiked, setReactionAmor) => {
  try {
    let likeQuery = query(
      reactionRef,
      where("postId", "==", postId),
      where("type", "==", "Te doy amor")
    );

    onSnapshot(likeQuery, (response) => {
      let likes = response.docs.map((doc) => doc.data());
      let likesCount = likes?.length;

      const isLiked = likes.some((like) => like.userId === userId);

      setReactionAmor(likesCount);
      setLiked(isLiked);
    });
  } catch (err) {
    console.log(err);
  }
};

export const postComment = (postId, comment, timeStamp, name) => {
  try {
    addDoc(commentsRef, {
      postId,
      comment,
      timeStamp,
      name,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getComments = (postId, setComments, setCommentsCount) => {
  try {
    let singlePostQuery = query(commentsRef, where("postId", "==", postId));
    onSnapshot(singlePostQuery, (response) => {
      const comments = response.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      setComments(comments);
      setCommentsCount(comments?.length);
    });
  } catch (err) {
    console.log(err);
  }
};

export const updatePost = (id, status) => {
  let docToUpdate = doc(postsRef, id);
  try {
    updateDoc(docToUpdate, status);
    console.log("Post has been updated!");
    // toast.success("Post has been updated!");
  } catch (err) {
    console.log(err);
  }
};

export const deletePost = (id) => {
  let docToDelete = doc(postsRef, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
    // toast.success("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const addConnection = (userId, targetId) => {
  try {
    let connectionToAdd = doc(connectionRef, `${userId}_${targetId}`);
    setDoc(connectionToAdd, { userId, targetId });
    // toast.success("Connection Added!");
  } catch (err) {
    console.log(err);
  }
};

export const getConnections = (userId, targetId, setIsConnected) => {
  try {
    let connectionsQuery = query(
      connectionRef,
      where("targetId", "==", targetId)
    );

    onSnapshot(connectionsQuery, (response) => {
      let connections = response.docs.map((doc) => doc.data());

      const isConnected = connections.some(
        (connection) => connection.userId === userId
      );

      setIsConnected(isConnected);
    });
  } catch (err) {
    console.log(err);
  }
};

export const postContent = (object) => {
  addDoc(postBlog, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postProgram = (object) => {
  addDoc(postProgramssRef, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postPrayer = (object) => {
  addDoc(postAddPrayers, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postCommunitys = async (object, user) => {
  try {
    const docRef = await addDoc(postCommunity, object);
    let uid = docRef.id;
    const secondData = {
      community: uid,
      user: user,
    };
    await addDoc(communityUserRef, secondData);
    console.log("Segunda inserción exitosa");
  } catch (error) {
    console.error("Error al añadir documento: ", error);
  }
};

export const getBlogs = (setAllStatus) => {
  const q = query(postBlog, orderBy("createdAt", "desc"), limit(9));
  onSnapshot(q, (response) => {
    setAllStatus(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getBlogsSearch = (setSearchResults, searchTerm) => {
  const q = query(
    postBlog,
    where("title", "<=", searchTerm + "\uf8ff"),
    where("title", ">=", searchTerm)
  );
  onSnapshot(q, (response) => {
    setSearchResults(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getLastBlogs = (setAllStatus) => {
  const q = query(postBlog, orderBy("createdAt", "desc"), limit(5));
  onSnapshot(q, (response) => {
    setAllStatus(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getBlog = async (id) => {
  let articulo = doc(postBlog, id);
  const docSnap = await getDoc(articulo);
  if (docSnap.exists()) {
    return docSnap.data();
  }
};

export const getNovena = async (id) => {
  let articulo = doc(postNovenas, id);
  const docSnap = await getDoc(articulo);
  if (docSnap.exists()) {
    return docSnap.data();
  }
};

export const getAddPrayer = async (id) => {
  let articulo = doc(postAddPrayers, id);
  const docSnap = await getDoc(articulo);
  if (docSnap.exists()) {
    return docSnap.data();
  }
};

export const getPrayerOnly = async (id) => {
  let articulo = doc(postAddPrayers, id);
  const docSnap = await getDoc(articulo);
  if (docSnap.exists()) {
    return docSnap.data();
  }
};

export const getPrayer = async (id) => {
  let articulo = doc(postPrayers, id);
  const docSnap = await getDoc(articulo);
  if (docSnap.exists()) {
    return docSnap.data();
  }
};

export const postBlogComment = (postId, comment, timeStamp, name) => {
  try {
    addDoc(commentsBlogRef, {
      postId,
      comment,
      timeStamp,
      name,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getBlogComments = (id, setComments) => {
  try {
    let singlePostQuery = query(
      commentsBlogRef,
      where("postId", "==", id),
      orderBy("timeStamp")
    );
    onSnapshot(singlePostQuery, (response) => {
      const comments = response.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setComments(comments);
    });
  } catch (err) {
    console.log(err);
  }
};

export const getBlogCommentsCount = (id, setCountComments) => {
  try {
    let singlePostQuery = query(
      commentsBlogRef,
      where("postId", "==", id),
      orderBy("timeStamp")
    );
    onSnapshot(singlePostQuery, (response) => {
      const comments = response.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setCountComments(comments.length);
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateViews = async (id) => {
  let docToUpdate = doc(postBlog, id);
  try {
    const docSnap = await getDoc(docToUpdate);
    const newView = docSnap.data().views + 1;
    updateDoc(docToUpdate, { views: newView });
  } catch (err) {
    console.log(err);
  }
};

export const updateBadge = async (id) => {
  let docToUpdate = doc(postThanksRef, id);
  try {
    const docSnap = await getDoc(docToUpdate);
    const newView = (docSnap.data().status = true);
    updateDoc(docToUpdate, { status: newView });
  } catch (err) {
    console.log(err);
  }
};

export const updateBlog = (id, data) => {
  let docToUpdate = doc(postBlog, id);
  try {
    updateDoc(docToUpdate, data);
    // toast.success("Post has been updated!");
  } catch (err) {
    console.log(err);
  }
};

export const updateCommunity = (id, data) => {
  let docToUpdate = doc(postCommunity, id);
  try {
    updateDoc(docToUpdate, data);
    // toast.success("Post has been updated!");
  } catch (err) {
    console.log(err);
  }
};

export const updateChallenge = (id, data) => {
  let docToUpdate = doc(postChallenges, id);
  try {
    updateDoc(docToUpdate, data);
    // toast.success("Post has been updated!");
  } catch (err) {
    console.log(err);
  }
};

export const updateNovena = (id, data) => {
  let docToUpdate = doc(postNovenas, id);
  try {
    updateDoc(docToUpdate, data);
    // toast.success("Post has been updated!");
  } catch (err) {
    console.log(err);
  }
};

export const updateAddPrayer = (id, data) => {
  let docToUpdate = doc(postAddPrayers, id);
  try {
    updateDoc(docToUpdate, data);
    // toast.success("Post has been updated!");
  } catch (err) {
    console.log(err);
  }
};

export const updatePrayer = (id, data) => {
  let docToUpdate = doc(postPrayers, id);
  try {
    updateDoc(docToUpdate, data);
    // toast.success("Post has been updated!");
  } catch (err) {
    console.log(err);
  }
};

export const deleteBlog = (id) => {
  let docToDelete = doc(postBlog, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
    // toast.success("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const deletePrayer = (id) => {
  let docToDelete = doc(postPrayers, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
    // toast.success("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const deleteNovena = (id) => {
  let docToDelete = doc(postNovenas, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
    // toast.success("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const deleteChallenge = (id) => {
  let docToDelete = doc(postChallenges, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
    // toast.success("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const deleteAddPrayer = (id) => {
  let docToDelete = doc(postAddPrayers, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
    // toast.success("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const deleteCommunity = async (id) => {
  const q = query(communityUserRef, where("community", "==", id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (docs) => {
    try {
      let docToDeleteJoin = doc(communityUserRef, docs.id);
      await deleteDoc(docToDeleteJoin);
      console.log(`Documento ${docs.id} eliminado correctamente.`);
    } catch (error) {
      console.error(`Error al eliminar documento ${docs.id}:`, error);
    }
  });
  let docToDelete = doc(postCommunity, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const getCommunitys = (setAllCommunitys) => {
  const q = query(postCommunity, orderBy("timeStamp"));
  onSnapshot(q, (response) => {
    setAllCommunitys(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getCommunity = async (id) => {
  let articulo = doc(postCommunity, id);
  const docSnap = await getDoc(articulo);
  if (docSnap.exists()) {
    return docSnap.data();
  }
};

export const searchUser = async (userName, setUser, setError) => {
  const q = query(userRef, where("name", "==", userName));
  try {
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      setUser(null);
    } else {
      querySnapshot.forEach((doc) => {
        setUser(doc.data());
      });
    }
  } catch (error) {
    setError(true);
  }
};

export const selectUser = async (currentUser, user, setUser, setUserName) => {
  //check whether the group(chats in firestore) exists, if not create
  console.log(
    currentUser.id > user.userID
      ? currentUser.id + user.userID
      : user.userID + currentUser.id
  );
  const combinedId =
    currentUser.id > user.userID
      ? currentUser.id + user.userID
      : user.userID + currentUser.id;
  try {
    const res = await getDoc(doc(chatRef, combinedId));
    if (!res.exists()) {
      //create a chat in chats collection
      await setDoc(doc(chatRef, combinedId), { messages: [] });
      //create user chats
      await updateDoc(doc(userChatRef, currentUser.id), {
        [combinedId + ".userInfo"]: {
          uid: user.userID,
          name: user.name,
        },
        [combinedId + ".date"]: serverTimestamp(),
      });
      await updateDoc(doc(userChatRef, user.userID), {
        [combinedId + ".userInfo"]: {
          uid: currentUser.id,
          name: currentUser.name,
        },
        [combinedId + ".date"]: serverTimestamp(),
      });
    } else {
      console.log("wtf");
    }
  } catch (err) {
    console.log(err);
  }
  setUser(null);
  setUserName("");
};

export const getChats = async (uid, setChats) => {
  let articulo = doc(userChatRef, uid);
  const docSnap = await getDoc(articulo);
  if (docSnap.exists()) {
    return setChats(docSnap.data());
  }
};

export const getMessage = async (chatId, setMessages) => {
  onSnapshot(doc(chatRef, chatId), (doc) => {
    if (doc.exists()) {
      setMessages(doc.data().messages);
    }
  });
};

export const updateChats = async (data, text, currentUser, setText) => {
  await updateDoc(doc(chatRef, data.chatId), {
    messages: arrayUnion({
      id: uuid(),
      text,
      senderId: currentUser,
      date: Timestamp.now(),
    }),
  });

  await updateDoc(doc(userChatRef, currentUser), {
    [data.chatId + ".lastMessage"]: {
      text,
    },
    [data.chatId + ".date"]: serverTimestamp(),
  });

  await updateDoc(doc(userChatRef, data.user.uid), {
    [data.chatId + ".lastMessage"]: {
      text,
    },
    [data.chatId + ".date"]: serverTimestamp(),
  });

  setText("");
};

export const joinCommunity = (postId) => {
  try {
    addDoc(userCommunityRef, {
      postId,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addEvent = (data) => {
  try {
    addDoc(eventRef, data);
  } catch (err) {
    console.log(err);
  }
};

export const getNovenas = (setAllNovenas) => {
  const q = query(postNovenas, orderBy("timeStamp"));
  onSnapshot(q, (response) => {
    setAllNovenas(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getPrayerList = (setPrayersList, id) => {
  const q = query(
    postAddPrayers,
    where("prayerGroup", "==", id),
    orderBy("prayerGroup")
  );
  onSnapshot(q, (response) => {
    setPrayersList(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getNovenasSearch = (setSearchResults, searchTerm) => {
  const normalizedQuery = searchTerm.toLowerCase();
  const q = query(
    postNovenas,
    where("title", ">=", normalizedQuery),
    where("title", "<=", normalizedQuery + "\uf8ff")
  );
  onSnapshot(q, (response) => {
    setSearchResults(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getPlayersSearch = (setSearchResults, searchTerm) => {
  const normalizedQuery = searchTerm.toLowerCase();
  const q = query(
    postPrayers,
    where("title", ">=", normalizedQuery),
    where("title", "<=", normalizedQuery + "\uf8ff")
  );
  onSnapshot(q, (response) => {
    setSearchResults(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getPrayers = (setAllPrayers) => {
  const q = query(postPrayers, orderBy("timeStamp"));
  onSnapshot(q, (response) => {
    setAllPrayers(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getChallenges = (setAllChallenges) => {
  const q = query(postChallenges, orderBy("timeStamp"));
  onSnapshot(q, (response) => {
    setAllChallenges(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getChallengerSearch = (setSearchResults, searchTerm) => {
  const q = query(
    postChallenges,
    where("title", ">=", searchTerm),
    where("title", "<=", searchTerm + "\uf8ff")
  );
  onSnapshot(q, (response) => {
    setSearchResults(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const addChallenge = (object) => {
  addDoc(postChallenges, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getChallenge = async (id) => {
  let articulo = doc(postChallenges, id);
  const docSnap = await getDoc(articulo);
  if (docSnap.exists()) {
    return docSnap.data();
  }
};

export const getMiracle = (setAllMiracles) => {
  const q = query(postMiracleRef, orderBy("createdAt", "desc"), limit(5));
  onSnapshot(q, (response) => {
    setAllMiracles(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getUserCommunity = async (setUsers, uid) => {
  const q = query(communityUserRef, where("community", "==", uid));
  onSnapshot(q, (response) => {
    const count = response.docs.map((docs) => {
      return { ...docs.data(), id: docs.id };
    });
    setUsers(count);
  });
};

export const getUsers = async (setUsers, uid) => {
  try {
    // Realizar la consulta para obtener las comunidades del usuario
    const comunidadesSnapshot = await query(
      communityUserRef,
      where("community", "==", uid)
    );
    const querySnapshot = await getDocs(comunidadesSnapshot);

    // Procesar las comunidades
    const comunidades = [];
    for (const docs of querySnapshot.docs) {
      const comunidadId = docs.data().user;

      // Consultar la información de la comunidad
      const comunidadSnapshot = await doc(postUsers, comunidadId);
      const querySnapshott = await getDoc(comunidadSnapshot);

      const comunidadData = { ...querySnapshott.data(), id: querySnapshott.id };
      comunidades.push(comunidadData);
    }
    setUsers(comunidades);
  } catch (error) {
    console.error("Error al obtener las comunidades del usuario:", error);
    throw error;
  }
};

export const updateAdminCommunity = async (uid, user) => {
  // const userRef = await query(postCommunity, uid);
  const userRef = doc(postCommunity, uid);
  await updateDoc(userRef, {
    userId: user,
  });
};

export const getCommunityUser = async (setCommunity, uid) => {
  try {
    // Realizar la consulta para obtener las comunidades del usuario
    const comunidadesSnapshot = await query(
      communityUserRef,
      where("user", "==", uid)
    );
    const querySnapshot = await getDocs(comunidadesSnapshot);

    // Procesar las comunidades
    const comunidades = [];
    for (const docs of querySnapshot.docs) {
      const comunidadId = docs.data().community;

      // Consultar la información de la comunidad
      const comunidadSnapshot = await doc(postCommunity, comunidadId);
      const querySnapshott = await getDoc(comunidadSnapshot);

      const comunidadData = { ...querySnapshott.data(), id: querySnapshott.id };
      comunidades.push(comunidadData);
    }
    setCommunity(comunidades);
  } catch (error) {
    console.error("Error al obtener las comunidades del usuario:", error);
    throw error;
  }
};

export const getCommunies = async (setCommunity, uid) => {
  try {
    const comunidadesSnapshot = await query(
      communityUserRef,
      where("user", "==", uid)
    );
    const querySnapshot = await getDocs(comunidadesSnapshot);
    const comunidades = [];
    for (const docs of querySnapshot.docs) {
      const comunidadId = docs.data().community;
      const comunidadSnapshot = await doc(postCommunity, comunidadId);
      const querySnapshott = await getDoc(comunidadSnapshot);
      if (querySnapshott.data().userId === uid) {
        const comunidadData = {
          label: querySnapshott.data().title,
          value: querySnapshott.id,
        };
        comunidades.push(comunidadData);
      }
    }
    setCommunity(comunidades);
  } catch (error) {
    console.error("Error al obtener las comunidades del usuario:", error);
    throw error;
  }
};

export const getNovenasCommunity = async (setNovenas, uid) => {
  try {
    // Realizar la consulta para obtener las comunidades del usuario
    const novenaSnapshot = await query(
      novenaCommunityRef,
      where("community", "==", uid)
    );
    const querySnapshot = await getDocs(novenaSnapshot);

    // Procesar las comunidades
    const novena = [];
    for (const docs of querySnapshot.docs) {
      const novenaId = docs.data().novena;

      // Consultar la información de la comunidad
      const novenasSnapshot = await doc(postNovenas, novenaId);
      const querySnapshott = await getDoc(novenasSnapshot);

      const novenaData = {
        ...querySnapshott.data(),
        id: querySnapshott.id,
        uid: docs.id,
      };
      novena.push(novenaData);
    }
    setNovenas(novena);
  } catch (error) {
    console.error("Error al obtener las comunidades del usuario:", error);
    throw error;
  }
};

export const getPrayerCommunity = async (setPrayers, uid) => {
  try {
    // Realizar la consulta para obtener las comunidades del usuario
    const prayersSnapshot = await query(
      prayerCommunityRef,
      where("community", "==", uid)
    );
    const querySnapshot = await getDocs(prayersSnapshot);

    // Procesar las comunidades
    const prayers = [];
    for (const docs of querySnapshot.docs) {
      const prayerId = docs.data().prayer;

      // Consultar la información de la comunidad
      const prayerSnapshot = await doc(postAddPrayers, prayerId);
      const querySnapshott = await getDoc(prayerSnapshot);

      const prayerData = {
        ...querySnapshott.data(),
        id: querySnapshott.id,
        uid: docs.id,
      };
      prayers.push(prayerData);
    }
    setPrayers(prayers);
  } catch (error) {
    console.error("Error al obtener las comunidades del usuario:", error);
    throw error;
  }
};

export const getPrayerUser = async (setPrayers, uid) => {
  try {
    // Realizar la consulta para obtener las comunidades del usuario
    const prayersSnapshot = await query(
      prayerUserRef,
      where("user", "==", uid)
    );
    const querySnapshot = await getDocs(prayersSnapshot);

    // Procesar las comunidades
    const prayers = [];
    for (const docs of querySnapshot.docs) {
      const prayerId = docs.data().prayer;

      // Consultar la información de la comunidad
      const prayerSnapshot = await doc(postPrayers, prayerId);
      const querySnapshott = await getDoc(prayerSnapshot);

      const prayerData = { ...querySnapshott.data(), id: querySnapshott.id };
      prayers.push(prayerData);
    }
    setPrayers(prayers);
  } catch (error) {
    console.error("Error al obtener las comunidades del usuario:", error);
    throw error;
  }
};

export const getNovenasUser = async (setNovenas, uid) => {
  try {
    // Realizar la consulta para obtener las comunidades del usuario
    const novenaSnapshot = await query(novenaUserRef, where("user", "==", uid));
    const querySnapshot = await getDocs(novenaSnapshot);

    // Procesar las comunidades
    const novena = [];
    for (const docs of querySnapshot.docs) {
      const novenaId = docs.data().novena;

      // Consultar la información de la comunidad
      const novenaSnapshot = await doc(postNovenas, novenaId);
      const querySnapshott = await getDoc(novenaSnapshot);

      const novenaData = { ...querySnapshott.data(), id: querySnapshott.id };
      novena.push(novenaData);
    }
    setNovenas(novena);
  } catch (error) {
    console.error("Error al obtener las comunidades del usuario:", error);
    throw error;
  }
};

export const getChallengeCommunity = async (setChallenges, uid) => {
  try {
    // Realizar la consulta para obtener las comunidades del usuario
    const challengeSnapshot = await query(
      challengeCommunityRef,
      where("community", "==", uid)
    );
    const querySnapshot = await getDocs(challengeSnapshot);

    // Procesar las comunidades
    const challenges = [];
    for (const docs of querySnapshot.docs) {
      const challengeId = docs.data().challenge;

      // Consultar la información de la comunidad
      const challengesSnapshot = await doc(postChallenges, challengeId);
      const querySnapshott = await getDoc(challengesSnapshot);

      const challengeData = {
        ...querySnapshott.data(),
        id: querySnapshott.id,
        uid: docs.id,
      };
      challenges.push(challengeData);
    }
    setChallenges(challenges);
  } catch (error) {
    console.error("Error al obtener las comunidades del usuario:", error);
    throw error;
  }
};

export const getChallengeUser = async (setChallenges, uid) => {
  try {
    // Realizar la consulta para obtener las comunidades del usuario
    const challengeSnapshot = await query(
      challengeUserRef,
      where("user", "==", uid)
    );
    const querySnapshot = await getDocs(challengeSnapshot);

    // Procesar las comunidades
    const challenge = [];
    for (const docs of querySnapshot.docs) {
      const challengeId = docs.data().challenge;

      // Consultar la información de la comunidad
      const challengeSnapshot = await doc(postChallenges, challengeId);
      const querySnapshott = await getDoc(challengeSnapshot);

      const challengesData = {
        ...querySnapshott.data(),
        id: querySnapshott.id,
      };
      challenge.push(challengesData);
    }
    setChallenges(challenge);
  } catch (error) {
    console.error("Error al obtener las comunidades del usuario:", error);
    throw error;
  }
};

export const getRelationCommunity = (setJoin, userId, communityId) => {
  const q = query(
    communityUserRef,
    where("user", "==", userId),
    where("community", "==", communityId)
  );
  onSnapshot(q, (response) => {
    setJoin(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getRelationPrayer = (setJoin, userId, prayerId) => {
  const q = query(
    prayerUserRef,
    where("user", "==", userId),
    where("prayer", "==", prayerId)
  );
  onSnapshot(q, (response) => {
    setJoin(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getRelationNovena = (setJoin, userId, novenaId) => {
  const q = query(
    novenaUserRef,
    where("user", "==", userId),
    where("novena", "==", novenaId)
  );
  onSnapshot(q, (response) => {
    setJoin(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const getRelationChallenges = (setJoin, userId, challengeId) => {
  const q = query(
    challengeUserRef,
    where("user", "==", userId),
    where("challenge", "==", challengeId)
  );
  onSnapshot(q, (response) => {
    setJoin(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const deleteComunityRef = (id) => {
  let docToDelete = doc(communityUserRef, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
    // toast.success("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const deletePrayerRef = (id) => {
  let docToDelete = doc(prayerUserRef, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
    // toast.success("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const deleteNovenaRef = (id) => {
  let docToDelete = doc(novenaUserRef, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
    // toast.success("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const deleteChallengeRef = (id) => {
  let docToDelete = doc(challengeUserRef, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
    // toast.success("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const postJoinCommunity = (object) => {
  addDoc(communityUserRef, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postJoinPrayer = (object) => {
  addDoc(prayerUserRef, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postJoinNovena = (object) => {
  addDoc(novenaUserRef, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const shareNovenaCommunity = (object) => {
  addDoc(novenasCommunityRef, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const sharePrayerCommunity = (object) => {
  addDoc(prayerCommunityRef, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postJoinChallenge = (object) => {
  addDoc(challengeUserRef, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPrayersDetail = async (id) => {
  let articulo = doc(postPrayers, id);
  const docSnap = await getDoc(articulo);
  if (docSnap.exists()) {
    return docSnap.data();
  }
};

export const addPrayer = (object) => {
  addDoc(postPrayers, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getNovenaDetail = async (id) => {
  let articulo = doc(postNovenas, id);
  const docSnap = await getDoc(articulo);
  if (docSnap.exists()) {
    return docSnap.data();
  }
};

export const addNovena = (object) => {
  addDoc(postNovenas, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const acceptCommunity = (id) => {
  let communityRef = doc(postCommunity, id);
  updateDoc(communityRef, {
    publish: true,
  });
};

export const resetPassword = (email) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      console.log("Password reset email sent!");
    })
    .catch((error) => {
      console.log(error.code);
      console.log(error.message);
      // ..
    });
};

export const postJoinPrayerCommunity = (object) => {
  addDoc(prayerCommunityRef, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postJoinChallengeCommunity = (object) => {
  addDoc(challengeCommunityRef, object)
    .then(() => {
      console.log("Post has been added successfully");
      //   toast.success("Post has been added successfully");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deletePrayerCommunity = (id) => {
  let docToDelete = doc(prayerCommunityRef, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
    // toast.success("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const deleteNovenaCommunity = (id) => {
  let docToDelete = doc(novenaCommunityRef, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const deleteChallengeCommunity = (id) => {
  let docToDelete = doc(challengeCommunityRef, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const getProgram = (setProgram, userID) => {
  const q = query(
    postProgramssRef,
    orderBy("dateOrder", "desc"),
    where("userId", "==", userID)
  );
  onSnapshot(q, (response) => {
    setProgram(
      response.docs.map((docs) => {
        return { ...docs.data(), id: docs.id };
      })
    );
  });
};

export const deleteNotification = (id) => {
  let docToDelete = doc(postThanksRef, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};

export const deleteProgramRef = (id) => {
  let docToDelete = doc(programRef, id);
  try {
    deleteDoc(docToDelete);
    console.log("Post has been Deleted!");
    // toast.success("Post has been Deleted!");
  } catch (err) {
    console.log(err);
  }
};
