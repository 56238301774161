import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  deleteAddPrayer,
  deletePrayerRef,
  getCommunityUser,
  getCurrentUser,
  getPrayerList,
  getRelationPrayer,
  postJoinPrayer,
  postJoinPrayerCommunity,
} from "../services/Firestore";
import { useLoaderData, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddIcon from "@mui/icons-material/Add";
import DialogPrayer from "../components/DialogPrayer";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

export async function loader({ params }) {
  const uid = params.prayId;
  return { uid };
}

export default function PrayersPreview() {
  let navigate = useNavigate();

  const { uid } = useLoaderData();

  const [id, setId] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [prayersList, setPrayersList] = useState([]);
  const [item, setItem] = useState({ audioName: "" });
  const [selectedAudio, setSelectedAudio] = useState("");
  const [prayerText, setPrayerText] = useState("");
  const [join, setJoin] = useState([]);
  const [openShare, setOpenShare] = useState(false);
  const [communitys, setCommunitys] = useState([]);
  const [community, setCommunity] = useState("");

  useMemo(() => {
    getPrayerList(setPrayersList, uid);
    getCurrentUser(setCurrentUser);
  }, [uid]);

  useEffect(() => {
    if (currentUser.id) {
      getRelationPrayer(setJoin, currentUser.userID, uid);
      getCommunityUser(setCommunitys, currentUser?.id);
    }
  }, [uid, currentUser.userID, currentUser.id]);

  const handleClose = () => {
    setOpen(false);
  };

  const cutHeader = (text) => {
    if (text.length > 28) {
      return text.slice(0, 28) + "...";
    } else {
      return text;
    }
  };

  const handleClickOpenShare = () => {
    setOpenShare(true);
  };

  const handleCloseShare = () => {
    setOpenShare(false);
  };

  const handleChange = (event) => {
    setCommunity(event.target.value);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCloseSnack}>
        LISTO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Container>
      <DialogPrayer
        open={open}
        handleClose={handleClose}
        selectedAudio={selectedAudio}
        prayerText={prayerText}
        item={item}
        uid={uid}
      />
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message="Se ha compartida la oracion"
        action={action}
      />
      <Dialog onClose={handleCloseShare} open={openShare}>
        <DialogTitle>Selecciona una comunidad</DialogTitle>
        <DialogContent>
          {communitys.length !== 0 && (
            <FormControl fullWidth size="small">
              <Select
                displayEmpty
                name="community"
                value={community}
                onChange={handleChange}
                defaultValue="Seleccione comunidad"
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  <em>Seleccione comunidad</em>
                </MenuItem>
                {communitys.map((item) => (
                  <MenuItem value={item.id}>{item.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShare}>Cancelar</Button>
          <Button
            onClick={() => {
              postJoinPrayerCommunity({
                prayer: id,
                community: community,
              });
              handleClickSnack();
              handleCloseShare();
            }}
          >
            Compartir
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Button
              variant="text"
              startIcon={<ArrowBackIosIcon />}
              sx={{ textTransform: "initial" }}
              onClick={() => navigate("/oraciones")}
            >
              Volver
            </Button>
            <Box sx={{ display: "flex" }}>
              {currentUser.type === "admin" && (
                <Button
                  variant="contained"
                  sx={{ textTransform: "initial", mr: 2 }}
                  startIcon={<AddIcon />}
                  onClick={() => navigate(`/addPray/${uid}`)}
                >
                  Agregar oración
                </Button>
              )}
              <Button
                variant="contained"
                sx={{ backgroundColor: join.length !== 0 ? "red" : "" }}
                startIcon={<BookmarkIcon />}
                onClick={() =>
                  join.length !== 0
                    ? deletePrayerRef(join[0].id)
                    : postJoinPrayer({
                        prayer: uid,
                        user: currentUser.userID,
                      })
                }
              >
                {join.length !== 0 ? "Quitar" : "Guardar"}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid container spacing={2}>
          {prayersList.map((item) => (
            <Grid item xs={12} sm={6} md={4}>
              {currentUser.type === "admin" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mr: 1,
                    mt: 1,
                  }}
                >
                  <IconButton
                    onClick={() => navigate(`/addPrayerUpdate/${item.id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => deleteAddPrayer(item.id)}>
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      handleClickOpenShare();
                      setId(item.id);
                    }}
                  >
                    <ArrowOutwardIcon />
                  </IconButton>
                </Box>
              )}
              <Card sx={{ mt: 3, borderRadius: 15 }}>
                <CardActionArea
                  onClick={() => navigate(`/prayerDetails/${item.id}`)}
                >
                  <CardMedia
                    component="img"
                    height="300"
                    image={item.image}
                    alt=""
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      color="black"
                      textAlign="center"
                    >
                      {cutHeader(item.id)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}
